import React, { useState } from 'react';
import { H4, Heading } from '../../components';
import css from './ListingPage.module.css';
import Icons from '../../components/Icons/Icons';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

const SectionCancellation = props => {
  const { text } = props;
  const [isVisible, setAccordionVisibility] = useState(false);

  const onToggleAccordion = () => {
    setAccordionVisibility(!isVisible);
  };

  return (
    <section className={classNames(css.sectionText, css.cancellationSec)}>
      <Heading as="h2" rootClassName={css.sectionHeading} onClick={onToggleAccordion}>
        Cancellation Policy
        <Icons name={isVisible ? 'upAngle' : 'downAngle'} />
      </Heading>
      {isVisible && (
        <div className={css.cancellationText}>
          <p>
            Cancel at least 72 hours prior to the start of your rental and receive a 75% refund.
          </p>
          <p>Cancel 48 - 72 hours prior to the start of your rental and receive a 50% refund.</p>
          <p>Cancel 24 - 48 hours prior to the start of your rental and receive a 25% refund. </p>
          <p>No refund for cancellations within 24 hours of the start of your rental.</p>
          <p> No refund for same day bookings.</p>
          <p> Platform fees are non-refundable after the booking is accepted.</p>
        </div>
      )}
    </section>
  );
};

export default SectionCancellation;
